import { useAtom } from "jotai";
import { getCurrencySymbolAtom } from "../../lib/country";
import { customerAtom } from "../../lib/customer";
import { intervalAtom } from "../../lib/interval";
import { productAtom, selectedPriceAtom } from "../../lib/product";
import { Interval } from "../../types/interval";
import { PhoneInput } from "../phone-input/PhoneInput";
import styles from "./CheckoutSummary.module.scss";

export const CheckoutSummary = () => {
	const [customer, setCustomer] = useAtom(customerAtom);
	const [product] = useAtom(productAtom);
	const [price] = useAtom(selectedPriceAtom);
	const [currencySymbol] = useAtom(getCurrencySymbolAtom);
	const [interval] = useAtom(intervalAtom);

	return (
		<>
			<h2 className="fs-4">Checkout Summary</h2>
			<p className="fs-7 lh-2 mb-4">
				Please enter the cell phone number that will be associated with the plan
				below. You will receive a text message with login credentials after
				checkout.
			</p>
			<div className={styles.summaryContainer}>
				<div className={styles.productInfo}>
					<span
						className={`d-inline-block fw-bold fs-7 ${styles.coverageCaption}`}
					>
						{product?.friendlyId}
					</span>
					<PhoneInput
						value={customer.phone}
						onChange={(phone) =>
							setCustomer((prev) => ({
								...prev,
								phone,
							}))
						}
						onChangePhoneValidity={(validity) => {
							setCustomer((prev) => ({
								...prev,
								isPhoneValid: validity,
							}));
						}}
						className={styles.phoneInputClass}
					/>
				</div>
				<span className={`fs-7 ${styles.priceContainer}`}>
					<span>
						{`${currencySymbol}${
							price?.currentPrice ? (price?.currentPrice / 100).toFixed(2) : ""
						}`}{" "}
					</span>
					<span>/ {interval === Interval.MONTHLY ? "month" : "year"}</span>
				</span>
			</div>
		</>
	);
};

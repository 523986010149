import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "./PaymentForm.module.scss";

export const PaymentForm = (props: {
	onTokenCreated: (token: any) => void;
	relatedRequestState: string;
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isCardComplete, setIsCardComplete] = useState(false);
	const [isExpDateComplete, setIsExpDateComplete] = useState(false);
	const [isCvcComplete, setIsCvcComplete] = useState(false);

	useEffect(() => {
		if (!stripe || !elements) {
			return;
		}

		if (isCardComplete && isExpDateComplete && isCvcComplete) {
			const card = elements.getElement("cardNumber");
			// @ts-ignore
			stripe.createToken(card).then((token) => {
				props.onTokenCreated(token);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCardComplete, isExpDateComplete, isCvcComplete, stripe, elements]);

	useEffect(() => {
		if (!stripe || !elements) {
			return;
		}

		if (props.relatedRequestState === "loading") {
			const card = elements.getElement("cardNumber");
			const cardExpiry = elements.getElement("cardExpiry");
			const cardCvc = elements.getElement("cardCvc");
			if (card && cardExpiry && cardCvc) {
				card.clear();
				cardExpiry.clear();
				cardCvc.clear();
			}
		}
	}, [props, stripe, elements]);

	return (
		<Form.Group>
			<div className={styles.cardInputsContainer}>
				<CardNumberElement
					id="card-number-element"
					onChange={(e) =>
						e.complete ? setIsCardComplete(true) : setIsCardComplete(false)
					}
					className={`form-control ${styles.cardNumber}`}
				/>
				<CardExpiryElement
					id="card-expirity-element"
					onChange={(e) =>
						e.complete
							? setIsExpDateComplete(true)
							: setIsExpDateComplete(false)
					}
					className={`form-control ${styles.cardExtraData}`}
				/>
				<CardCvcElement
					id="card-cvc-element"
					onChange={(e) =>
						e.complete ? setIsCvcComplete(true) : setIsCvcComplete(false)
					}
					className={`form-control ${styles.cardExtraData}`}
				/>
			</div>
		</Form.Group>
	);
};
